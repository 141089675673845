<div class="contenedor-resultado-nav">
  <div class="contenedor-listado-actuaciones">
    <div class="listado-actuaciones">
      <ul>
        <li *ngFor="let p of prestudios; let i = index">
          <button
            [ngClass]="{ seleccionada: p === prestudio_seleccionado }"
            (click)="seleccionarPrestudio(p)"
          >
            <p>{{ p.tipoActuacion }}</p>
            <p>{{ p.tipo_actuacion }}</p>
            <span>{{ p.fecha }}</span>
          </button>
        </li>
      </ul>
      <div class="listado-actuaciones-continuar"></div>
    </div>
    <div class="listado-actuaciones">
      <ul *ngIf="showingDispatch()">
        <li>
          <button
            (click)="seleccionarPrestudio(dispatch.study)"
            [ngClass]="{
              seleccionada: dispatch.study === prestudio_seleccionado
            }"
          >
            <p>REVISIÓN MANUAL</p>
            <p>{{ dispatch.createdAt | date: "yyyy-MM-dd" }}</p>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div class="contenedor-botones-header">
    <ul>
      <li>
        <button (click)="togggleHistorial()">
          <i class="fas fa-history"></i>
          <span>Historial</span>
        </button>
      </li>
      <li *ngIf="estado">
        <a [href]="'/study/' + estado.id">
          <button>
            <i class="fas fa-home"></i>
            <span>Volver</span>
          </button>
        </a>
      </li>
    </ul>
  </div>
</div>

<div *ngIf="estado" class="contenedor-info-estudio">
  Estudio de sociedad realizado automáticamente el
  <b>{{ limpiarFecha(estado.fecha) }}</b> por <b>{{ estado.abogado }}</b>
</div>
