<div class="app-study-documents-explorer">
  <div class="header">
    <mat-form-field subscriptSizing="dynamic" class="document-select">
      <mat-label>Documentos</mat-label>
      <mat-select
        [value]="selectedDocument"
        (selectionChange)="onSelectedDocumentChange($event)"
      >
        <mat-select-trigger>
          {{ selectedDocument?.name }}
          <span class="document-select-id">{{ selectedDocument?.id }}</span>
        </mat-select-trigger>
        <mat-option *ngFor="let document of documents" [value]="document">
          {{ document.name }}
          <span class="document-select-id">{{document.id}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="document">
    <app-loading-spinner *ngIf="loading" />
    <div *ngIf="selectedDocument?.content.type === 'html'">
      <app-html-document [document]="selectedDocument" [search]="search" />
    </div>
  </div>
</div>
