<div class="app-studies-table-wrapper">
  <app-studies-table-message [message]="message"></app-studies-table-message>
  <app-studies-table-filters
    [(year)]="year"
    [(month)]="month"
    [(customer)]="customer"
    [(customerLawFirm)]="customerLawFirm"
    [(selectFilters)]="currentSelectFilters"
    [searchLabels]="searchLabels"
    [(searchText)]="searchText"
    [(searchLabel)]="searchLabel"
    (filterChanged)="filterChanged()"
    (searchTextLabelChange)="searchTextLabelChanged()"
  />
  <app-studies-table-pagination
    [length]="count"
    [pageIndex]="studiesPageIndex"
    (handlePageEvent)="handlePageEvent($event)"
  />
  <app-studies-table
    [columns]="tableColumns"
    [loading]="loading"
    [studies]="studies"
    [sortDirection]="sortDirection"
    (sortChange)="handleSortEvent($event)"
  />
</div>
