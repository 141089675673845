import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortDirection } from '@angular/material/sort';

import { ROUTES } from '../../constants/routes';
import { StudiesTableStudy } from './types';

export enum STUDY_TABLE_COLUMN {
  APPROVED_DISPLAY = 'APPROVED_DISPLAY',
  BUSINESS_NAME = 'BUSINESS_NAME',
  CREATED_AT = 'CREATED_AT',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  EXECUTIVE_USERNAME = 'EXECUTIVE_USERNAME',
  DISPATCH_ID = 'DISPATCH_ID',
  DISPATCH_STATUS_DISPLAY = 'DISPATCH_STATUS_DISPLAY',
  LAWYER_NAME = 'LAWYER_NAME',
  RUT = 'RUT',
  SGL_CODE = 'SGL_CODE',
  STATUS_DISPLAY = 'STATUS_DISPLAY',
}

export enum STUDY_TABLE_SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc',
}

@Component({
  selector: 'app-studies-table',
  templateUrl: './studies-table.component.html',
  styleUrls: ['./studies-table.component.scss'],
})
export class StudiesTableComponent {
  STUDY_TABLE_COLUMN = STUDY_TABLE_COLUMN;
  ROUTES = ROUTES;
  @Input() loading: boolean;
  @Input() columns: STUDY_TABLE_COLUMN[];
  @Input() studies: StudiesTableStudy[];
  @Input() sortDirection: STUDY_TABLE_SORT_DIRECTION;

  @Output() sortChange = new EventEmitter<STUDY_TABLE_SORT_DIRECTION>();

  onSortChange({ direction }: { direction: SortDirection }) {
    this.sortChange.emit(direction as STUDY_TABLE_SORT_DIRECTION);
  }

  studyPagePath(id: string, route: ROUTES): string {
    return `/${ROUTES.STUDY__BETA}/${id}/${route}`;
  }
}
