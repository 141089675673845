import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * TODO: https://equipolegalbot.atlassian.net/browse/LB-925
 * Create types for:
 * - PreStudy
 * - Dispatch
 * - StudyStatus
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type PreStudy = any;
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type Dispatch = any;
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type StudyStatus = any;

const enum DISPATCH_STATUSES {
  DELIVERED = 2,
}

@Component({
  selector: 'app-resultado-nav',
  templateUrl: './resultado-nav.component.html',
})
export class ResultadoNavComponent {
  @Input() prestudios: PreStudy[];
  @Input() prestudio_seleccionado: PreStudy;
  @Input() estado: StudyStatus;
  @Input() dispatch: Dispatch;

  @Output() seleccionar: EventEmitter<PreStudy> = new EventEmitter();
  @Output() mostrar_historial: EventEmitter<boolean> = new EventEmitter();

  togggleHistorial() {
    this.mostrar_historial.emit(true);
  }

  showingDispatch() {
    if (!this.dispatch) {
      return false;
    }
    return this.dispatch.status === DISPATCH_STATUSES.DELIVERED;
  }

  seleccionarPrestudio(prestudio: PreStudy) {
    this.seleccionar.emit(prestudio);
  }

  limpiarFecha(f: string): string {
    if (!f) return '- -';

    return `
      ${f.substring(6, 8)}-${f.substring(4, 6)}-${f.substring(0, 4)}
      ${f.substring(8, 10)}:${f.substring(10, 12)}:${f.substring(12, 14)}
    `;
  }
}
