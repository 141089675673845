import { Component } from '@angular/core';

import { Dispatch, SearchFilter, SearchLabel } from '../../types';
import { DispatchesService } from '../../services/dispatches.service';
import { DISPATCH_STATUSES } from '../../constants/statuses';
import { LEGALBOT_LAWFIRM } from '../../constants/lawFirms';
import { SELECT_FILTER } from '../../components/studies-table/studies-table-wrapper/studies-table-wrapper.component';
import { STUDY_TABLE_COLUMN } from '../../components/studies-table/studies-table.component';
import { StudiesTableStudy } from '../../components/studies-table/types';
import { PaginatedResponse } from '../../services/types';

const DEFAULT_CUSTOMER = '';
const DEFAULT_LAWFIRM_ID = LEGALBOT_LAWFIRM.lawFirm;
const DEFAULT_STATUS_VALUE = DISPATCH_STATUSES.find(
  (status) => status.displayName === 'en estudio',
).value;

@Component({
  selector: 'app-dispatch',
  templateUrl: './dispatches.component.html',
  styleUrls: ['./dispatches.component.scss'],
})
export class DispatchesComponent {
  tableColumns = [
    STUDY_TABLE_COLUMN.DISPATCH_ID,
    STUDY_TABLE_COLUMN.RUT,
    STUDY_TABLE_COLUMN.BUSINESS_NAME,
    STUDY_TABLE_COLUMN.CUSTOMER_NAME,
    STUDY_TABLE_COLUMN.LAWYER_NAME,
    STUDY_TABLE_COLUMN.DISPATCH_STATUS_DISPLAY,
  ];
  textFilterLabels = [SearchLabel.RUT, SearchLabel.BUSINESS_NAME];
  defaultTextFilterLabel = SearchLabel.RUT;
  selectFilters = [
    {
      filter: SELECT_FILTER.DISPATCH_STATUS,
      defaultValue: DEFAULT_STATUS_VALUE,
    },
    { filter: SELECT_FILTER.CUSTOMER, defaultValue: DEFAULT_CUSTOMER },
    {
      filter: SELECT_FILTER.CUSTOMER_LAWFIRM,
      defaultValue: DEFAULT_LAWFIRM_ID,
    },
  ];

  constructor(private dispatchesService: DispatchesService) {
    this.getStudies = this.getStudies.bind(this);
  }

  makeStudyTable(dispatch: Dispatch): StudiesTableStudy {
    return {
      ...dispatch,
      id: dispatch.studyId,
      lastDispatch: dispatch,
      executive: { customer: { name: dispatch.customer } },
    };
  }

  async getStudies(
    params: SearchFilter,
    pageUrl: string,
  ): Promise<PaginatedResponse<StudiesTableStudy[]>> {
    return await this.dispatchesService
      .getDispatches(params, pageUrl)
      .then((response) => {
        const dispatches = response.results;

        if (response.error) {
          return response;
        }

        return {
          ...response,
          results: dispatches.map(this.makeStudyTable),
        };
      });
  }
}
