import { Component } from '@angular/core';

import { environment } from '../../../environments/environment';
import { SELECT_FILTER } from '../../components/studies-table/studies-table-wrapper/studies-table-wrapper.component';
import { SearchFilter, SearchLabel } from '../../types';
import { StudiesService } from '../../services/studies/studies.service';
import { STUDY_TABLE_COLUMN } from '../../components/studies-table/studies-table.component';
import { PaginatedResponse } from '../../services/types';
import { StudiesTableStudy } from '../../components/studies-table/types';

const FINALIZED_STATUS = 3;
const DEFAULT_CUSTOMER_FILTER = environment.defaultCustomerFilterId;

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
})
export class CustomersComponent {
  constructor(private studiesService: StudiesService) {
    this.getStudies = this.getStudies.bind(this);
  }

  tableColumns = [
    STUDY_TABLE_COLUMN.CREATED_AT,
    STUDY_TABLE_COLUMN.CUSTOMER_NAME,
    STUDY_TABLE_COLUMN.EXECUTIVE_USERNAME,
    STUDY_TABLE_COLUMN.RUT,
    STUDY_TABLE_COLUMN.BUSINESS_NAME,
    STUDY_TABLE_COLUMN.APPROVED_DISPLAY,
    STUDY_TABLE_COLUMN.STATUS_DISPLAY,
  ];
  textFilterLabels = [
    SearchLabel.RUT,
    SearchLabel.BUSINESS_NAME,
    SearchLabel.EXECUTIVE,
  ];
  defaultTextFilterLabel = SearchLabel.RUT;
  selectFilters = [
    { filter: SELECT_FILTER.STATUS, defaultValue: FINALIZED_STATUS },
    { filter: SELECT_FILTER.CUSTOMER, defaultValue: DEFAULT_CUSTOMER_FILTER },
  ];

  getStudies(
    params: SearchFilter,
    pageUrl: string,
  ): Promise<PaginatedResponse<StudiesTableStudy[]>> {
    return this.studiesService.getStudies(params, pageUrl);
  }
}
