import { Component, Input, OnInit } from '@angular/core';

import { enrichStudies } from '../utils/enrich';
import { getDayMonthYear } from '../../../utils/dates';
import { PaginatedResponse } from '../../../services/types';
import { PaginatorEvent } from '../studies-table-pagination/types/studies-table-pagination';
import { SearchFilter, SearchLabel } from '../../../types';
import { STUDY_STATUSES, DISPATCH_STATUSES } from '../../../constants/statuses';
import {
  STUDY_TABLE_COLUMN,
  STUDY_TABLE_SORT_DIRECTION,
} from '../studies-table.component';
import { StudiesTableStudy, StudyTableSelectFilter } from '../types';
import { SELECT_FILTER } from '../studies-table-filters/studies-table-filters.component';
export { SELECT_FILTER };

const ORDERING_BY = {
  [STUDY_TABLE_SORT_DIRECTION.ASC]: 'created_at',
  [STUDY_TABLE_SORT_DIRECTION.DESC]: '-created_at',
};

type StudySelectFilter = {
  filter: SELECT_FILTER;
  defaultValue: string | number;
};

const SELECT_FILTER_SETTINGS = {
  [SELECT_FILTER.STATUS]: {
    label: 'Estado',
    options: STUDY_STATUSES,
  },
  [SELECT_FILTER.DISPATCH_STATUS]: {
    label: 'Estado',
    options: DISPATCH_STATUSES,
  },
};

const UNSPECIFIC_ERROR_MESSAGE = 'Error al cargar tu historial';

@Component({
  selector: 'app-studies-table-wrapper',
  templateUrl: './studies-table-wrapper.component.html',
  styleUrls: ['./studies-table-wrapper.component.scss'],
})
export class StudiesTableWrapperComponent implements OnInit {
  @Input() getStudies: (
    params: SearchFilter,
    pageUrl?: string,
  ) => Promise<PaginatedResponse<StudiesTableStudy[]>>;
  @Input() defaultSearchLabel: SearchLabel;
  @Input() tableColumns: STUDY_TABLE_COLUMN[];
  @Input() searchLabels: SearchLabel[] = [];
  @Input() selectFilters: StudySelectFilter[];

  count: number;
  currentSelectFilters: (StudyTableSelectFilter & { filter: SELECT_FILTER })[];
  customer: string;
  customerLawFirm: string;
  loading = false;
  message: string;
  month: string;
  next: string;
  previous: string;
  searchLabel: SearchLabel;
  searchText: string;
  sortDirection = STUDY_TABLE_SORT_DIRECTION.ASC;
  studies: StudiesTableStudy[] = [];
  studiesPageIndex = 0;
  year: string;

  get genericSelectFilters() {
    return this.selectFilters.filter((selectFilter) =>
      Object.keys(SELECT_FILTER_SETTINGS).includes(
        selectFilter.filter.toString(),
      ),
    );
  }

  ngOnInit() {
    const { month, year } = getDayMonthYear();
    const customerFilter = this.selectFilters?.find(
      (selectFilter) => selectFilter.filter === SELECT_FILTER.CUSTOMER,
    );
    const customerLawFirmFilter = this.selectFilters?.find(
      (selectFilter) => selectFilter.filter === SELECT_FILTER.CUSTOMER_LAWFIRM,
    );

    this.customer = customerFilter?.defaultValue.toString();
    this.customerLawFirm = customerLawFirmFilter?.defaultValue.toString();
    this.month = month;
    this.year = year;
    this.searchLabel = this.defaultSearchLabel;
    this.setCurrentSelectFilters();

    this.loadStudies().then(() => this.setCurrentSelectFilters());
  }

  getSelectFilterValue(filter: SELECT_FILTER): string {
    if (!this.currentSelectFilters) {
      return null;
    }

    return this.currentSelectFilters
      .find((selectFilter) => selectFilter.filter === filter)
      ?.value.toString();
  }

  setCurrentSelectFilters() {
    if (!this.selectFilters) {
      return;
    }

    this.currentSelectFilters = this.genericSelectFilters.map(
      (selectFilter) => {
        const filter = SELECT_FILTER_SETTINGS[selectFilter.filter];

        return {
          ...filter,
          filter: selectFilter.filter,
          value: selectFilter.defaultValue,
        };
      },
    );
  }

  async loadStudies() {
    if (this.loading) {
      return;
    }

    this.message = null;
    this.loading = true;
    this.studiesPageIndex = 0;

    const isFilteredByOpenSearch = Boolean(this.searchLabel && this.searchText);
    const searchLabelFilter = isFilteredByOpenSearch && {
      [this.searchLabel]: this.searchText,
    };

    const studiesResponse = await this.getStudies({
      customerId: this.customer?.toString(),
      lawFirmId: this.customerLawFirm?.toString(),
      month: this.month,
      ordering: ORDERING_BY[this.sortDirection],
      status:
        this.getSelectFilterValue(SELECT_FILTER.STATUS) ||
        this.getSelectFilterValue(SELECT_FILTER.DISPATCH_STATUS),
      year: this.year,
      ...searchLabelFilter,
    });
    this.loading = false;

    if (studiesResponse.error) {
      this.message = UNSPECIFIC_ERROR_MESSAGE;

      return;
    }

    this.studies = enrichStudies(studiesResponse.results);
    this.previous = studiesResponse.previous;
    this.next = studiesResponse.next;
    this.count = studiesResponse.count;
  }

  async goToPage(pageUrl: string) {
    if (this.loading) {
      return;
    }

    this.message = null;
    this.loading = true;

    const studiesResponse = await this.getStudies({}, pageUrl);

    this.loading = false;

    if (studiesResponse.error) {
      this.message = UNSPECIFIC_ERROR_MESSAGE;

      return;
    }

    this.studies = enrichStudies(studiesResponse.results);
    this.previous = studiesResponse.previous;
    this.next = studiesResponse.next;
    this.count = studiesResponse.count;
  }

  filterChanged() {
    this.loadStudies();
  }

  searchTextLabelChanged() {
    if (!this.searchText) {
      return;
    }

    this.loadStudies();
  }

  handlePageEvent(event: PaginatorEvent) {
    if (event.pageIndex > event.previousPageIndex) {
      this.studiesPageIndex++;
      this.goToPage(this.next);

      return;
    }

    if (event.pageIndex < event.previousPageIndex) {
      this.studiesPageIndex--;
      this.goToPage(this.previous);

      return;
    }
  }

  handleSortEvent(sortDirection: STUDY_TABLE_SORT_DIRECTION) {
    this.sortDirection = sortDirection;
    this.loadStudies();
  }
}
